<template>
  <a class="dd-banner" :href="onLine" target="_blank">
    <img src="/static/img/dd_e_banner.jpg" />
  </a>
  <DDZSItem1 />
  <DDItem img="/static/img/dd_e_img1.png" title="专属权益" desc="提供32项总价值38万的专属权益。" bg-color="#e5f6ff" />
  <DDItem img="/static/img/dd_p_img3.jpg" title="高级功能" desc="通过并行节点、业务规则、动态流程、OCR识别、单选数据联动、多条件组合等能力支持企业90%以上的业务流程。" />
  <DDZSItem4 />
  <DDCase :list="list" bg-color="#f6f8fa" :card-outline="true" />
</template>
<script>
import { onLine } from "@/data.ts";
import DDZSItem1 from '../../components/dingtalk/DDZSItem1_v2.vue';
import DDCase from '../../components/dingtalk/DDCase_v2.vue';
import DDItem from '../../components/dingtalk/DDItem_v2.vue';
import DDZSItem4 from '../../components/dingtalk/DDZSItem4_v2.vue';
export default {
  components: {
    DDZSItem1,
    DDCase,
    DDItem,
    DDZSItem4,
  },
  data() {
    return {
      onLine,
      list: [
        { img: '/static/img/dd-img10.jpg', title: '柳钢集团', remark: '柳钢集团和钉钉合力打造智慧生产管控平台，实现生产管理、人员管理、过程管理等多个环节的降本增效。' },
        { img: '/static/img/dd-img5.jpg', title: '长城汽车', remark: '长城汽车&钉钉合力打造简单、高效、协同、安全的数字化专属办公平台，重新定义移动办公方式，全面实现“五个在线”。' },
        { img: '/static/img/dd-img6.jpg', title: '金科集团', remark: '基于钉钉专属定制的美好金科是集办公、协同、知识于一体的统一移动平台，它实现了组织、业务和沟通的无缝集成和实时在线。' },
        { img: '/static/img/dd-img7.jpg', title: '蒙牛集团', remark: '专属钉钉帮助蒙牛实现了集团、各分子公司和经销商体系的数字化平台构建，实现组织内外部的高效沟通协同，业务数字化全场景覆盖。' },
        { img: '/static/img/dd-img8.jpg', title: '立白集团', remark: '专属钉钉通过混合云部署架构实现专属存储，确保数据安全，全面打造立白智能协同平台，实现零售生态、物流生态的数字化运营。' },
        { img: '/static/img/dd-img9.jpg', title: '百丽（Belle）', remark: '通过专属钉钉让百丽业务协同效率大幅提升 ，助力产品优化、管理决策；疫情期间，实现了300多个城市的门店员工的及时响应和调动。' },
      ]
    }
  }
}
</script>
<style lang="less" scoped>
.dd-banner {
  color: #fff;
  display: block;

  >img {
    width: 100%;
  }

  >h3 {
    font-size: 20px;
    font-weight: bold;
  }

  >p {
    font-size: 15px;
    font-weight: 300;
    line-height: 1.8;
    margin-top: 10px;
  }

  >button {
    height: 40px;
    background: #F23D49;
    border-radius: 3px;
    font-size: 16px;
    font-weight: bold;
    color: #FFFFFF;
    margin-top: 30px;
    border: 0 none;
    width: 115px;
  }
}
</style>
