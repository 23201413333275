<template>
  <div class="dd-zs_item_4">
    <h3>专属安全功能&nbsp;&nbsp;沉浸式体验中心</h3>
    <p>在钉钉强大的组织、沟通、协同、业务和生态在线的基座上助力企业打造 自己专属的数字化办公运营平台。</p>
    <ul>
      <li>
        <img src="/static/img/dd_e_icon_4.png"/>
        <div>
          <h2>账号安全</h2>
          <p>企业账号  ·  账号数据安全  ·  账号使用安全</p>
        </div>
      </li>
      <li>
        <img src="/static/img/dd_e_icon_5.png"/>
        <div>
          <h2>访问安全</h2>
          <p>可信设备  ·  IP围栏  ·  网络代理</p>
        </div>
      </li>
      <li>
        <img src="/static/img/dd_e_icon_6.png"/>
        <div>
          <h2>数据安全</h2>
          <p>明、暗水印  ·  敏感词管控  ·  三色管控  ·  文件病毒扫描</p>
        </div>
      </li>
    </ul>
  </div>
</template>
<script lang="ts">
export default {
  mounted() {

  }
}
</script>
<style lang="less" scoped>
.dd-zs_item_4 {
  padding: 40px 20px;
  color: white;
  background-color: #0082fe;

  >h3 {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }

  >p {
    font-size: 16px;
    font-weight: 300;
    margin-top: 15px;
    text-align: justify;
    margin-bottom: 30px;
    line-height: 1.8;
  }

  ul{
    li{
      display: flex;
      align-items: flex-start;
      &:not(:last-child){
        margin-bottom: 20px;
      }
      >img{
        width: 50px;
        max-width: 50px;
        margin-right: 20px;
      }
      h2{
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 10px;
      }
      p{
        font-size: 15px;
        font-weight: 300;
        text-align: justify;
        line-height: 1.8;
      }
    }
  }
}
</style>
