<template>
  <div class="dd-zs_item_1">
    <h3>安全生产力平台</h3>
    <p>助力企业打造专属、安全、开放的<br/>数字化办公运营平台</p>
    <ul>
      <li>
        <img src="/static/img/dd_e_icon_1.png"/>
        <div>
          <h2>保障全链路安全</h2>
          <p>终端、账号、应用、数据 等全方位安全。</p>
        </div>
      </li>
      <li>
        <img src="/static/img/dd_e_icon_2.png"/>
        <div>
          <h2>企业专属个性化</h2>
          <p>企业专属App，UI、框架、应用、文案 支持个性化。</p>
        </div>
      </li>
      <li>
        <img src="/static/img/dd_e_icon_3.png"/>
        <div>
          <h2>开放集成&专属AI</h2>
          <p>专享能力和数据开放，安全准确的 企业级AI应用。</p>
        </div>
      </li>
    </ul>
  </div>
</template>
<script lang="ts">
export default {
  mounted() {

  }
}
</script>
<style lang="less" scoped>
.dd-zs_item_1 {
  padding: 30px 20px;
  color: #383838;

  >h3 {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }

  >p {
    font-size: 16px;
    font-weight: 300;
    margin-top: 15px;
    text-align: center;
    margin-bottom: 30px;
    line-height: 1.8;
  }

  ul{
    li{
      padding: 30px 25px;
      background-color: #f6f8fa;
      border-radius: 10px;
      box-shadow: 0 0 0 1px #e8eaed;
      display: flex;
      align-items: center;
      &:not(:last-child){
        margin-bottom: 15px;
      }
      >img{
        width: 50px;
        max-width: 50px;
        margin-right: 20px;
      }
      h2{
        font-size: 17px;
        font-weight: bold;
        margin-bottom: 10px;
      }
      p{
        font-size: 15px;
        font-weight: 300;
        text-align: justify;
        line-height: 1.8;
      }
    }
  }
}
</style>
